<script>
// import Moment from "moment";
import dayjs from 'dayjs';

export default {
  name: 'MeetingDatePicker',
  props: {
    label: {
      type: String,
      required: false,
      default: 'Meeting Date',
    },
    initialDate: {
      type: String,
      required: false,
      default: '2020-12-21',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      modal: false,
      chosenDate: undefined,
    };
  },
  computed: {
    thisDate: {
      get() {
        return this.chosenDate || this.initialDate;
      },
      set(chosenDate) {
        this.chosenDate = chosenDate;
      },
    },
  },
  watch: {
    readonly(val) {
      if (val) {
        this.chosenDate = this.initialDate;
      }
    },
    initialDate(val) {
      if (val) {
        this.chosenDate = this.initialDate;
      }
    },
  },
  methods: {
    rejectChosenDate() {
      this.chosenDate = this.initialDate;
      this.modal = false;
    },
    applyChosenDate() {
      this.$emit('saved', this.chosenDate);
      this.modal = false;
    },
    allowOnlyWeekdays(val) {
      // var moment = new Moment(val);
      const date = dayjs(val);
      // var dayOfWeek = moment.day();
      const dayOfWeek = date.day();
      return dayOfWeek > 0 && dayOfWeek < 6;
    },
  },
};
</script>

<template>
  <div class="meeting-date-picker">
    <v-dialog ref="dialog" v-model="modal" persistent width="290px">
      <template #activator="{ on }">
        <v-text-field
          v-model="thisDate"
          :label="label"
          :disabled="disabled"
          :return-value.sync="chosenDate"
          :readonly="readonly"
          class="date-text-field"
          append-icon="mdi-calendar"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="thisDate"
        :disabled="disabled"
        :allowed-dates="allowOnlyWeekdays"
        :readonly="readonly"
        scrollable
        no-title
      >
        <v-spacer />
        <v-btn text color="grfn darken-2" @click="rejectChosenDate"
          >Cancel</v-btn
        >
        <v-btn text color="action" @click="applyChosenDate">OK</v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<style>
.v-text-field.v-input--is-disabled input[type='text'],
.v-text-field.v-select.v-input--is-disabled .v-select__selection--disabled,
.v-text-field.v-input--is-disabled textarea,
.date-text-field.v-input--is-disabled input {
  color: #000000de;
}
.date-text-field.v-text-field.v-input--is-disabled
  > .v-input__control
  > .v-input__slot:before {
  border-image: none;
}
.date-text-field .v-icon.v-icon--disabled {
  visibility: hidden;
}
</style>
